import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

  html {
    font-family: "Raleway", Montserrat,-apple-system,system-ui,BlinkMacSystemFont,Roboto,"Helvetica Neue",Arial,sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x:hidden;
    width:100vw;
  }

  a, li, button {
    font-family: "Raleway";
  }

  #___gatsby {
    overflow: hidden;
  }
`;
